import React, { useCallback, useEffect, useState } from 'react';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import './AnalyticsInstallment.css';
import PropTypes from 'prop-types';
import { analyticsFilters, getSelectedFilters } from '../../app/filters';
import getInstallmentData from './data';
import { Button, Card, UncontrolledTooltip } from 'reactstrap';
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  LineChart,
  Line,
} from 'recharts';
import {
  downloadSpreadsheet,
  formatValue,
  getColour,
  selectedFilters,
  shadeColour,
} from '../../app/utils';
import ToggleButton from '../../components/ToggleButton';
import ReactDataSheet from 'react-datasheet';
import ContextBar from '../../components/V2/ContextBar/ContextBar';
import { bindActionCreators } from 'redux';
import { setLoading } from '../../redux/actions/filters.action';
import { connect } from 'react-redux';
import LabelledSpinner from '../../components/LabelledSpinner';

const generateDisplayData = (chart) => {
  const displayData = chart.isComparison ? [] : chart.data;
  const keys = chart.isComparison ? Object.keys(chart.data) : ['Attrition'];
  if (chart.isComparison) {
    const periodData = {};
    for (const comparisonValue of keys) {
      for (const row of chart.data[comparisonValue]) {
        const period = row['Period'];
        if (!(period in periodData)) {
          periodData[period] = {
            Period: period,
            [comparisonValue]: row['Attrition'],
          };
        } else {
          periodData[period][comparisonValue] = row['Attrition'];
        }
      }
    }
    const periods = Object.keys(periodData);
    periods.sort((a, b) => Number(a) - Number(b));
    for (const period of periods) {
      displayData.push(periodData[period]);
    }
  }
  return [displayData, keys];
};

/**
 * Analytics Installment Component
 * @return {JSX} The component rendered
 */
function AnalyticsInstallment(props) {
  const { filterSelections, filterValues, setLoading } = props;
  const isLoading = filterSelections.loading;

  const [data, setData] = useState({
    attrition: {},
    attritionAccumulated: [],
    volume: [],
    attritionByPeriodTable: [],
    attritionCohortPeriod: [],
    attritionCohortPeriodAccumulated: [],
    retention: [],
  });
  const [attritionChart, setAttritionChart] = useState({
    data: [],
    accumulate: false,
    isComparison: false,
  });
  const [tableData, setTableData] = useState({
    data: [],
    tableChoice: 'tableOne',
  });
  const [overallTableData, setOverallTableData] = useState({
    data: [],
    tableChoice: 'tableOne',
  });

  /**
   * Fetches Data from required data endpoints and returns them to the screen
   * through a hook.
   */
  useEffect(() => {
    if (filterSelections.applied) {
      setLoading(true);
      const filters = getSelectedFilters(
        filterSelections.filterSelections,
        filterValues,
        analyticsFilters,
      );
      const abortController = new AbortController();
      getInstallmentData(filters, abortController.signal).then((res) => {
        if (!abortController.signal.aborted) {
          setData(res);
          setLoading(false);
        }
      });
      return () => abortController.abort();
    }
    return undefined;
  }, [filterSelections.applied, filterSelections.filterSelections, filterValues]);

  useEffect(() => {
    // Conditionally set the data for the attrition chart
    if (attritionChart.accumulate) {
      setAttritionChart({
        data: data.attritionAccumulated.results,
        accumulate: true,
        isComparison: data.attritionAccumulated.isComparison,
      });
    } else {
      setAttritionChart({
        data: data.attrition.results,
        accumulate: false,
        isComparison: data.attrition.isComparison,
      });
    }
    if (tableData.tableChoice === 'tableOne') {
      setTableData({
        data: data.attritionCohortPeriod,
        tableChoice: 'tableOne',
      });
    }
    if (tableData.tableChoice === 'tableTwo') {
      setTableData({
        data: data.attritionCohortPeriodAccumulated,
        tableChoice: 'tableTwo',
      });
    }
    if (tableData.tableChoice === 'tableThree') {
      setTableData({
        data: data.retention,
        tableChoice: 'tableThree',
      });
    }
    if (overallTableData.tableChoice === 'tableOne') {
      setOverallTableData({
        data: data.attritionByPeriodTable,
        tableChoice: 'tableOne',
      });
    }
  }, [data, tableData.tableChoice, attritionChart.accumulate, overallTableData.tableChoice]);

  const unaccumulatedChart = {
    data: data.attrition.results,
    accumulate: false,
    isComparison: data.attrition.isComparison,
  };
  const accumulatedChart = {
    data: data.attritionAccumulated.results,
    accumulate: true,
    isComparison: data.attritionAccumulated.isComparison,
  };

  /**
   * Toggle the attrition chart selection by switching the data and buttons
   * around.
   */
  function toggleAttritionChart() {
    // Conditionally set the data for the attrition chart
    if (attritionChart.accumulate) {
      setAttritionChart(unaccumulatedChart);
    } else {
      setAttritionChart(accumulatedChart);
    }
  }

  /**
   * Turn on the overview attrition table
   * @param {String} e The toggle button name calling the function
   */
  function toggleOverviewTableData(e) {
    if (e === 'tableOne') {
      setOverallTableData({
        data: data.attritionByPeriodTable,
        tableChoice: 'tableOne',
      });
    }
  }

  /**
   * Turn on the attrition table
   * @param {String} e The toggle button name calling the function
   */
  function toggleTableData(e) {
    if (e === 'tableOne') {
      setTableData({
        data: data.attritionCohortPeriod,
        tableChoice: 'tableOne',
      });
    }
    if (e === 'tableTwo') {
      setTableData({
        data: data.attritionCohortPeriodAccumulated,
        tableChoice: 'tableTwo',
      });
    }
    if (e === 'tableThree') {
      setTableData({
        data: data.retention,
        tableChoice: 'tableThree',
      });
    }
  }

  const handleDownloadSpreadsheet = useCallback(() => {
    const [unaccumulatedData] = generateDisplayData(unaccumulatedChart);
    const [accumulatedData] = generateDisplayData(accumulatedChart);
    const attritionData = [
      {
        tabName: 'Attrition by Instalment',
        tabData: unaccumulatedData,
        header: ['Period'],
      },
      {
        tabName: 'Accumulated Attrition',
        tabData: accumulatedData,
        header: ['Period'],
      },
    ];

    downloadSpreadsheet(
      'Attrition_View',
      selectedFilters(filterSelections.filterSelections),
      attritionData,
    );
  }, [data, filterSelections.filterSelections]);

  const [displayData, keys] = generateDisplayData(attritionChart);

  return (
    <div className={'wholepage'}>
      <header>
        <PrimaryBar />
      </header>
      <div className={'main-content'}>
        <SecondaryBar />
        <ContextBar
          title={'Regular Giving Performance (Installment View)'}
          footer={'Details of regular giving performance over time.'}
          shortcutFilters={[
            'age-range',
            'appeal',
            'campaign',
            'channel',
            'gift-start-financial-year',
            'payment-method',
            'supplier',
            'transaction-month',
          ]}
          sidebarFilters={analyticsFilters}
        />
        {filterSelections.applied && isLoading ? (
          <LabelledSpinner
            isLoading={filterSelections.applied && isLoading}
            style={{ width: '5rem', height: '5rem', margin: '0 auto' }}
            color="primary"
          >
            Calculating...
          </LabelledSpinner>
        ) : (
          <></>
        )}
        {!filterSelections.applied || isLoading ? (
          <></>
        ) : (
          <div className="page-content no-pad-top">
            <div className="installment_row">
              <div className="graph_section">
                <div className="graph_btns">
                  <ToggleButton
                    componentSelectorName="instalment-switch-chart-attrition_by_instalment"
                    text={'Attrition by Installment'}
                    active={!attritionChart.accumulate}
                    onClick={toggleAttritionChart}
                    toolTipText="The attrition of donors from their regular giving subscriptions per installment. The installment is calculated as the number of months since their subscription commenced."
                  />
                  <ToggleButton
                    componentSelectorName="instalment-switch-chart-accumulated_attrition"
                    text={'Accumulated Attrition'}
                    active={attritionChart.accumulate}
                    onClick={toggleAttritionChart}
                    toolTipText="The attrition of donors from their regular giving subscriptions per installment, accumulated to the end of that period. The installment is calculated as the number of months since their subscription commenced."
                  />
                  <Button className={'download_spreadsheet'} onClick={handleDownloadSpreadsheet}>
                    <i className="fas fa-download " />
                    Download Spreadsheet
                  </Button>
                </div>
                <div className="graph_wrapper">
                  <Card className="installment_graph">
                    <ResponsiveContainer>
                      <LineChart
                        data={displayData}
                        margin={{
                          top: 50,
                          bottom: 50,
                          left: 50,
                          right: 50,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="Period"
                          label={{
                            value: 'Period Number',
                            position: 'insideBottom',
                            offset: -10,
                          }}
                        />
                        <YAxis
                          tickFormatter={(v) => formatValue(v, '##0%')}
                          label={{
                            value: 'Attrition %',
                            angle: -90,
                            position: 'insideLeft',
                          }}
                        />
                        <Legend
                          payload={keys.map((key, index) => {
                            return {
                              value: key,
                              type: 'line',
                              color: getColour(index),
                            };
                          })}
                          height={36}
                          verticalAlign="top"
                        />
                        <Tooltip formatter={(v) => formatValue(v, '##0.00%')} />
                        {keys.map((key, index) => (
                          <Line
                            key={`chart_${key}`}
                            type="monotone"
                            dataKey={key}
                            stroke={shadeColour(getColour(index), 10)}
                            strokeWidth={2}
                          />
                        ))}
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                  <UncontrolledTooltip
                    target={'volume-container'}
                    position={'right'}
                    hideArrow={true}
                  >
                    The number of donors who have a regular giving subscription and the average gift
                    of those donors by month.
                  </UncontrolledTooltip>
                  <Card className="volume_table">
                    <div className="volume_container" id="volume-container">
                      <h5>Volume and Average Gift by Cohort</h5>
                      <div className="volume_table_data">
                        <ReactDataSheet data={data.volume} valueRenderer={(cell) => cell.value} />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <div className="overall_table_container">
              <div className="graph_btns cohort_graph">
                <ToggleButton
                  componentSelectorName="instalment-switch-table-overall-attrition"
                  text={'Overall Attrition by Installment'}
                  active={tableData.tableChoice === 'tableOne'}
                  name={'tableOne'}
                  toolTipText={
                    'The overall attrition of donors from their regular giving subscriptions per installment in the first 5 years. The installment is calculated as the number of months since their subscription commenced.'
                  }
                  onClick={toggleOverviewTableData}
                />
              </div>
              <div className="overall_table_data">
                <ReactDataSheet data={overallTableData.data} valueRenderer={(cell) => cell.value} />
              </div>
            </div>
            <div className="cohort_tbl">
              <div className="graph_btns cohort_graph">
                <ToggleButton
                  componentSelectorName="instalment-switch-table-attrition_by_cohort_instalment"
                  text={'Attrition by Cohort and Installment'}
                  active={tableData.tableChoice === 'tableOne'}
                  name={'tableOne'}
                  toolTipText={
                    'The attrition of donors within that given cohort and installment number. The values are not cumulative and specific to that cohort only.'
                  }
                  onClick={toggleTableData}
                />
                <ToggleButton
                  componentSelectorName="instalment-switch-table-accumulated_attrition_by_cohort_instalment"
                  text={'Accumulated Attrition by Cohort and Installment'}
                  name={'tableTwo'}
                  toolTipText={
                    'The attrition of donors within that given cohort and installment number. The values are cumulative across the cohort.'
                  }
                  active={tableData.tableChoice === 'tableTwo'}
                  onClick={toggleTableData}
                />
                <ToggleButton
                  componentSelectorName="instalment-switch-table-retention_by_cohort_instalment"
                  text={'Retention by Cohort and Installment'}
                  toolTipText={
                    'The retention across the cohort by installment. This shows the number of donors remaining as time progresses.'
                  }
                  active={tableData.tableChoice === 'tableThree'}
                  name={'tableThree'}
                  onClick={toggleTableData}
                />
              </div>
              <div className="cohorttbl_data">
                <ReactDataSheet data={tableData.data} valueRenderer={(cell) => cell.value} />
              </div>
            </div>
          </div>
        )}
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoading: setLoading,
    },
    dispatch,
  );
};

AnalyticsInstallment.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
  setLoading: PropTypes.func,
};

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsInstallment);
