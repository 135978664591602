import React, { useCallback, useEffect, useState } from 'react';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import ComparisonCard from '../../components/ComparisonCard';
import ToggleButton from '../../components/ToggleButton';
import { Button, Card } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { analyticsFilters, getComparisonFilter, getSelectedFilters } from '../../app/filters';
import {
  convertDateToMonthYearFull,
  downloadSpreadsheet,
  formatValue,
  getColour,
  selectedFilters,
  shadeColour,
} from '../../app/utils';
import './AnalyticsCalendar.css';
import ContextBar from '../../components/V2/ContextBar/ContextBar';
import { bindActionCreators } from 'redux';
import { setLoading } from '../../redux/actions/filters.action';
import { connect } from 'react-redux';
import ReactDataSheet from 'react-datasheet';
import Pages from '../../components/Pages';
import { getCalendarData, getCanceledDonors } from './data';
import { mergeComparison } from '../../app/data';
import CardLoading from '../../components/CardLoading';
import LabelledSpinner from '../../components/LabelledSpinner';

/**
 * Analytics Calendar page
 * @param {Object} props
 * @return {JSX}
 */
function AnalyticsCalendar(props) {
  const { filterSelections, filterValues, setLoading } = props;
  const [isPageLoading, setPageLoading] = useState(false);
  const [topChart, setTopChart] = useState(0);
  const [table, setTable] = useState(0);
  const isLoading = filterSelections.loading;

  const base = {
    currentYear: null,
    vsPreviousYear: null,
    isComparison: false,
  };
  const [data, setData] = useState({
    totalRevenue: { ...base },
    latestRevenue: null,
    signups: { ...base },
    active: { ...base },
    latestSignups: null,
    dropCount: { ...base },
    latestDropCount: null,
    decline: { ...base },
    latestDecline: null,
    revenueByMonth: [],
    donorsPerMonth: [],
    signupsByMonth: [],
    dropCountByMonth: [],
    canceledDonors: { data: [], size: 0 },
    totalGiftCount: { ...base },
    latestTotalGiftCount: null,
    totalGiftCountByMonth: [],
    activeGiftCount: { ...base },
    latestActiveGiftCount: null,
    activeGiftCountByMonth: [],
    terminatedGiftCount: { ...base },
    latestTerminatedGiftCount: null,
    terminatedGiftCountByMonth: [],
  });
  const [topChartData, setTopChartData] = useState({
    data: [],
    keys: [],
    yAxisLabel: 'Revenue $',
    formatter: (value) => formatValue(value, '$###,0##'),
  });
  const [tableData, setTableData] = useState({
    data: data.canceledDonors.data,
    size: data.canceledDonors.size,
  });
  const [pageNumber, setPageNumber] = useState(1);

  /**
   * Hook to load the initial data for the page and on filter change
   */
  useEffect(() => {
    if (filterSelections.applied) {
      setLoading(true);
      const filters = getSelectedFilters(
        filterSelections.filterSelections,
        filterValues,
        analyticsFilters,
      );
      const offset = (pageNumber - 1) * 20;
      const abortController = new AbortController();
      getCalendarData(filters, offset, abortController.signal).then((res) => {
        if (!abortController.signal.aborted) {
          setData(res);
          setLoading(false);
        }
      });
      return () => abortController.abort();
    }
    return undefined;
  }, [filterSelections.applied, filterSelections.filterSelections, filterValues]);

  const getRevenueData = useCallback(() => {
    const revenueData = mergeComparison(
      data.revenueByMonth,
      'revenue',
      getComparisonFilter(filterSelections.filterSelections, analyticsFilters),
    );
    revenueData.yAxisLabel = 'Revenue $';
    revenueData.formatter = (value) => formatValue(value, '$###,0##');
    return revenueData;
  }, [data, filterSelections.filterSelections]);

  const getDonorData = useCallback(() => {
    const donorData = mergeComparison(
      data.donorsPerMonth,
      'donorCount',
      getComparisonFilter(filterSelections.filterSelections, analyticsFilters),
    );
    donorData.yAxisLabel = 'Donors';
    donorData.formatter = (value) => formatValue(value, '###,0##');
    return donorData;
  }, [data, filterSelections.filterSelections]);

  const getSignupData = useCallback(() => {
    const signupData = mergeComparison(
      data.signupsByMonth,
      'donorCount',
      getComparisonFilter(filterSelections.filterSelections, analyticsFilters),
    );
    signupData.yAxisLabel = 'Signups';
    signupData.formatter = (value) => formatValue(value, '###,0##');
    return signupData;
  }, [data, filterSelections.filterSelections]);

  const getDropCountData = useCallback(() => {
    const dropCountData = mergeComparison(
      data.dropCountByMonth,
      'dropCount',
      getComparisonFilter(filterSelections.filterSelections, analyticsFilters),
    );
    dropCountData.yAxisLabel = 'Cancelled Donors';
    dropCountData.formatter = (value) => formatValue(value, '###,0##');
    return dropCountData;
  }, [data, filterSelections.filterSelections]);

  /**
   * Hook that runs when the top chart buttons are modified
   */
  useEffect(() => {
    if (topChart === 0) {
      setTopChartData(getRevenueData());
    } else if (topChart === 1) {
      setTopChartData(getDonorData());
    } else if (topChart === 2) {
      setTopChartData(getSignupData());
    } else if (topChart === 3) {
      setTopChartData(getDropCountData());
    }
  }, [topChart, data, filterSelections.filterSelections]);

  // Effect that triggers when the table selection is changed, this changes the
  // data loaded into the react data sheet.
  useEffect(() => {
    switch (table) {
      case 0:
        setTableData({
          data: data.canceledDonors.data,
          size: data.canceledDonors.size,
        });
        break;
      default:
        break;
    }
  }, [table, data]);

  const handlePageChange = (pageNumber) => {
    if (filterSelections.applied) {
      setPageLoading(true);
      const filters = getSelectedFilters(
        filterSelections.filterSelections,
        filterValues,
        analyticsFilters,
      );
      const currData = data;
      const offset = (pageNumber - 1) * 20;
      setPageNumber(pageNumber);
      switch (table) {
        case 0:
          getCanceledDonors(filters, offset).then((res) => {
            currData.canceledDonors = res;
            setData(currData);
            setTableData({
              data: res.data,
              size: res.size,
            });
            setPageLoading(false);
          });
          break;
        default:
          break;
      }
    }
  };

  const handleDownloadSpreadsheet = useCallback(() => {
    const chartData = [
      {
        tabName: 'Revenue by Month',
        tabData: getRevenueData().data,
        header: ['transactionMonth'],
      },
      {
        tabName: 'Active Donors by Month',
        tabData: getDonorData().data,
        header: ['transactionMonth'],
      },
      {
        tabName: 'Signups by Gift Start Month',
        tabData: getSignupData().data,
        header: ['transactionMonth'],
      },
      {
        tabName: 'Cancelled Donors by Month',
        tabData: getDropCountData().data,
        header: ['transactionMonth'],
      },
    ];

    downloadSpreadsheet(
      'Calendar_View',
      selectedFilters(filterSelections.filterSelections),
      chartData,
    );
  }, [
    getRevenueData,
    getDonorData,
    getSignupData,
    getDropCountData,
    filterSelections.filterSelections,
  ]);

  return (
    <div className={'wholepage'}>
      <header>
        <PrimaryBar />
      </header>
      <div className={'main-content'}>
        <SecondaryBar />
        <ContextBar
          title={'Regular Giving Performance (Calendar View)'}
          footer={'View the Regular Giving performance of the organisation over time.'}
          shortcutFilters={[
            'age-range',
            'appeal',
            'campaign',
            'channel',
            'gift-start-financial-year',
            'payment-method',
            'supplier',
            'transaction-month',
          ]}
          sidebarFilters={analyticsFilters}
        />
        {filterSelections.applied && isLoading ? (
          <LabelledSpinner
            isLoading={filterSelections.applied && isLoading}
            style={{ width: '5rem', height: '5rem', margin: '0 auto' }}
            color="primary"
          >
            Calculating...
          </LabelledSpinner>
        ) : (
          <></>
        )}
        {!filterSelections.applied || isLoading ? (
          <></>
        ) : (
          <div className="content_section">
            <div className="page-content no-pad-top">
              <div className="top_content row1">
                <ComparisonCard
                  componentSelectorName="calendar-card-total_revenue"
                  title="Total Revenue"
                  toolTipText="The total revenue across all regular giving for the specified time period."
                  trend={data.totalRevenue.vsPreviousYear}
                  figure={formatValue(data.totalRevenue.currentYear, '$###,##0')}
                  footerFigure={formatValue(Math.abs(data.totalRevenue.vsPreviousYear), '0%')}
                  defaultText={
                    data.latestRevenue !== null
                      ? formatValue(data.latestRevenue.revenue, '$###,##0') +
                        ' in ' +
                        convertDateToMonthYearFull(data.latestRevenue.transactionMonth)
                      : 'No Data'
                  }
                  isComparison={data.totalRevenue.isComparison}
                  isLoading={isLoading}
                />
                <ComparisonCard
                  componentSelectorName="calendar-card-total_signups"
                  title="Total Signups by Gift Start Date"
                  toolTipText="The total number of donors who start the regular giving subscriptions for the given time period, regardless of the regular giving subscriptions' current status."
                  trend={data.signups.vsPreviousYear}
                  figure={formatValue(data.signups.currentYear, '###,##0')}
                  footerFigure={formatValue(Math.abs(data.signups.vsPreviousYear), '0%')}
                  defaultText={
                    data.latestSignups !== null
                      ? formatValue(data.latestSignups.donorCount, '###,##0') +
                        ' in ' +
                        convertDateToMonthYearFull(data.latestSignups.transactionMonth)
                      : 'No Data'
                  }
                  isComparison={data.signups.isComparison}
                  isLoading={isLoading}
                />
                <ComparisonCard
                  componentSelectorName="calendar-card-total_active"
                  title="Total Active"
                  toolTipText="The total number of donors whose regular giving subscriptions are active and had at least 1 payment transaction in the relevant period."
                  figure={formatValue(data.active.currentYear, '###,##0')}
                  defaultText={'As at last data load.'}
                  isLoading={isLoading}
                />
                <ComparisonCard
                  componentSelectorName="calendar-card-total_cancelled"
                  toolTipText="The total number of donors whose regular giving subscriptions have cancelled for the given time period."
                  title="Cancelled"
                  trend={data.dropCount.vsPreviousYear}
                  figure={formatValue(data.dropCount.currentYear, '###,##0')}
                  footerFigure={formatValue(Math.abs(data.dropCount.vsPreviousYear), '0%')}
                  defaultText={
                    data.latestDropCount !== null
                      ? formatValue(data.latestDropCount.dropCount, '###,##0') +
                        ' in ' +
                        convertDateToMonthYearFull(data.latestDropCount.transactionMonth)
                      : 'No Data'
                  }
                  isComparison={data.dropCount.isComparison}
                  isLoading={isLoading}
                />
                <ComparisonCard
                  componentSelectorName="calendar-card-avg_decline"
                  title="Average Decline"
                  toolTipText="The total number of regular giving subscriptions cancelled across all donors for the given time period."
                  trend={data.decline.vsPreviousYear}
                  figure={formatValue(data.decline.currentYear, '###,##0.##%')}
                  footerFigure={formatValue(Math.abs(data.decline.vsPreviousYear), '0%')}
                  defaultText={
                    data.latestDecline !== null
                      ? formatValue(data.latestDecline.averageDecline, '#0.##%') +
                        ' in ' +
                        convertDateToMonthYearFull(data.latestDecline.transactionMonth)
                      : 'No Data'
                  }
                  isComparison={data.decline.isComparison}
                  isLoading={isLoading}
                />
              </div>
              <div className="top_content row1">
                <ComparisonCard
                  componentSelectorName="calendar-card-total_gifts"
                  title="Total Recurring Gifts"
                  toolTipText="The total regular giving subscriptions for the specified time period."
                  trend={data.totalGiftCount.vsPreviousYear}
                  figure={formatValue(data.totalGiftCount.currentYear, '###,##0')}
                  footerFigure={formatValue(Math.abs(data.totalGiftCount.vsPreviousYear), '0%')}
                  defaultText={
                    data.latestTotalGiftCount !== null
                      ? formatValue(data.latestTotalGiftCount.giftCount, '###,##0') +
                        ' in ' +
                        convertDateToMonthYearFull(data.latestTotalGiftCount.transactionMonth)
                      : 'No Data'
                  }
                  isComparison={data.totalGiftCount.isComparison}
                  isLoading={isLoading}
                />
                <ComparisonCard
                  componentSelectorName="calendar-card-total_active_gifts"
                  title="Total Active Recurring Gifts"
                  toolTipText="The total regular giving subscriptions that are active and have started during the specified time period."
                  trend={data.activeGiftCount.vsPreviousYear}
                  figure={formatValue(data.activeGiftCount.currentYear, '###,##0')}
                  footerFigure={formatValue(Math.abs(data.activeGiftCount.vsPreviousYear), '0%')}
                  defaultText={
                    data.latestActiveGiftCount !== null
                      ? formatValue(data.latestActiveGiftCount.giftCount, '###,##0') +
                        ' in ' +
                        convertDateToMonthYearFull(data.latestActiveGiftCount.transactionMonth)
                      : 'No Data'
                  }
                  isComparison={data.activeGiftCount.isComparison}
                  isLoading={isLoading}
                />
                <ComparisonCard
                  componentSelectorName="calendar-card-total_cancelled_gifts"
                  title="Total Terminated Recurring Gifts"
                  toolTipText="The total regular giving subscriptions that are terminated and had started during the specified time period."
                  trend={data.terminatedGiftCount.vsPreviousYear}
                  figure={formatValue(data.terminatedGiftCount.currentYear, '###,##0')}
                  footerFigure={formatValue(
                    Math.abs(data.terminatedGiftCount.vsPreviousYear),
                    '0%',
                  )}
                  defaultText={
                    data.latestTerminatedGiftCount !== null
                      ? formatValue(data.latestTerminatedGiftCount.giftCount, '###,##0') +
                        ' in ' +
                        convertDateToMonthYearFull(data.latestTerminatedGiftCount.transactionMonth)
                      : 'No Data'
                  }
                  isComparison={data.terminatedGiftCount.isComparison}
                  isLoading={isLoading}
                />
              </div>
              <div className="graph_section">
                <div className="ac_graph_section">
                  <div className="graph_btns">
                    <ToggleButton
                      componentSelectorName="calendar-switch-revenue_by_month"
                      text={'Revenue by Month'}
                      active={topChart === 0}
                      toolTipText={
                        'The total revenue across all donors from regular giving campaigns per month.'
                      }
                      onClick={() => setTopChart(0)}
                    />
                    <ToggleButton
                      componentSelectorName="calendar-switch-active_donors_by_month"
                      text={'Total Number of Active Donors by Month'}
                      toolTipText={
                        'The total number of active regular giving subscriptions per month. Active is defined as having donated in that month at any stage, regardless of end of month status.'
                      }
                      active={topChart === 1}
                      onClick={() => setTopChart(1)}
                    />
                    <ToggleButton
                      componentSelectorName="calendar-switch-signups_by_month"
                      text={'Signups by Gift Start Month'}
                      toolTipText={
                        'The total number of donors that their new regular giving subscriptions start per month.'
                      }
                      active={topChart === 2}
                      onClick={() => setTopChart(2)}
                    />
                    <ToggleButton
                      componentSelectorName="calendar-switch-cancelled_by_month"
                      text={'Number of Cancelled Donors by Month'}
                      toolTipText={
                        'The total number of donors cancelling their regular giving subscriptions per month.'
                      }
                      active={topChart === 3}
                      onClick={() => setTopChart(3)}
                    />
                    <Button className={'download_spreadsheet'} onClick={handleDownloadSpreadsheet}>
                      <i className="fas fa-download " />
                      Download Spreadsheet
                    </Button>
                  </div>
                  <Card className="ac_chart_section">
                    <ResponsiveContainer>
                      <AreaChart
                        data={topChartData.data}
                        margin={{ top: 50, bottom: 50, left: 50, right: 50 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="transactionMonth"
                          label={{
                            value: 'Transaction Month',
                            position: 'insideBottom',
                            offset: -15,
                          }}
                        />
                        <YAxis
                          tickFormatter={topChartData.formatter}
                          label={{
                            value: topChartData.yAxisLabel,
                            angle: -90,
                            offset: -10,
                            position: 'insideLeft',
                          }}
                        />
                        {topChartData.keys.length > 1 && (
                          <Legend
                            payload={topChartData.keys.map((key, index) => {
                              return {
                                value: key,
                                type: 'line',
                                color: getColour(index),
                              };
                            })}
                            height={36}
                            verticalAlign="top"
                          />
                        )}
                        <Tooltip formatter={topChartData.formatter} />
                        {topChartData.keys.map((key, index) => (
                          <Area
                            key={`chart_${key}`}
                            type="monotone"
                            dataKey={key}
                            stroke={shadeColour(getColour(index), 10)}
                            fill={getColour(index)}
                            dot
                          />
                        ))}
                      </AreaChart>
                    </ResponsiveContainer>
                  </Card>
                </div>
              </div>
              <div className="graph_section">
                <div className="graph_btns">
                  <ToggleButton
                    componentSelectorName="calendar-switch-cancelled_donors"
                    text={'Canceled Donors'}
                    active={table === 0}
                    toolTipText={'List of donors who canceled donation.'}
                    onClick={() => setTable(0)}
                  />
                </div>
                <Card>
                  <CardLoading isLoading={isPageLoading}>Loading Page...</CardLoading>
                  <ReactDataSheet data={tableData.data} valueRenderer={(cell) => cell.value} />
                </Card>
                <Pages
                  onChange={(e) => handlePageChange(e)}
                  dataSetLength={tableData.size}
                  itemsPerPage={20}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoading: setLoading,
    },
    dispatch,
  );
};

AnalyticsCalendar.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
  setLoading: PropTypes.func,
};

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsCalendar);
